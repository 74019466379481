<template>
  <div>
    <SettingsNavigation :links="nestedLinks">
      <RestrictedButton
        :disabled="$route.name === 'settings.brand.create'"
        :to="{ name: 'settings.brand.create' }"
        permission="brand.create"
        action-class="button is-black"
      >
        {{ $t('forms.add_brand') }}
      </RestrictedButton>
    </SettingsNavigation>

    <div class="Settings__Children">
      <Authorize :permission="$route.meta.permission || true">
        <router-view />
      </Authorize>
    </div>
  </div>
</template>

<script>
import Authorize from '@/components/core/Authorize.vue'
import RestrictedButton from '@/components/core/RestrictedButton.vue'
import SettingsNavigation from '@/pages/settings/components/SettingsNavigation.vue'

export default {
  components: {
    Authorize,
    RestrictedButton,
    SettingsNavigation
  },

  computed: {
    nestedLinks () {
      return [
        { label: this.$t('pages.settings.brand.navigation.brand'), to: { name: 'settings.brand.brand' }, icon: 'fire' },
        { label: this.$t('pages.settings.brand.navigation.members'), to: { name: 'settings.brand.members' }, icon: 'users' },
        { label: this.$t('pages.settings.brand.navigation.tiers'), to: { name: 'settings.brand.tiers' }, icon: 'tier' },
        { label: this.$t('pages.settings.brand.navigation.rss'), to: { name: 'settings.brand.rss' }, icon: 'feed' }
      ]
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/styles/vueStyleUtils.scss';

.SettingsNavigation__slot {
  position: absolute;
  right: 0;
  top: 0;
}
</style>
